<template>
    <div>
        <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="user_openid" label="用户ID" align="center">
                <template slot-scope="scope">
                    {{ scope.row.user_openid }}
                </template>
            </el-table-column>
            <el-table-column prop="app_id" label="厂商ID" align="center">
                <template slot-scope="scope">
                    {{ scope.row.app_id }}
                </template>
            </el-table-column>
            <el-table-column prop="amount" label="充值金额(元)" align="center">
                <template slot-scope="scope">
                    {{ scope.row.amount }}
                </template>
            </el-table-column>
            <el-table-column prop="recharge_type" label="充值类型" align="center">
                <template slot-scope="scope">
                    {{ scope.row.recharge_type }}
                </template>
            </el-table-column>
            <el-table-column prop="device" label="设备型号" align="center">
                <template slot-scope="scope">
                    {{ scope.row.device }}
                </template>
            </el-table-column>
            <el-table-column prop="ip" label="IP" align="center">
                <template slot-scope="scope">
                    {{ scope.row.ip }}
                </template>
            </el-table-column>
            <el-table-column prop="country" label="国家" align="center">
                <template slot-scope="scope">
                    {{ scope.row.country }}
                </template>
            </el-table-column>
            <el-table-column prop="province" label="省份" align="center">
                <template slot-scope="scope">
                    {{ scope.row.province }}
                </template>
            </el-table-column>
            <el-table-column prop="city" label="城市" align="center">
                <template slot-scope="scope">
                    {{ scope.row.city }}
                </template>
            </el-table-column>
            <el-table-column prop="created" label="创建日期" align="center">
                <template slot-scope="scope">
                    {{ scope.row.created }}
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination background
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="pageSum">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Table",
        data() {
            return {
                // 表格数据
                tableData: [],
                TotalData: [],
                currentPage: 1, // 当前页
                pageSum: 0, // 总个数
                pageSize: 2, // 每页显示个数
            }
        },
        methods: {
            handleCurrentChange(val) {
                let start = (val-1)*this.pageSize
                this.tableData = this.TotalData.slice(start, start+this.pageSize)
            }
        },
        mounted() {
            this.$API.getRechargeInfo().then(res => {
                this.TotalData = res.data.data
                this.pageSum = this.TotalData.length
                this.tableData = this.TotalData.slice(0, 0+this.pageSize)
            })
        }
    }
</script>

<style scoped lang="scss">
.block {
    float: right;
    margin: 10px 10px;
}
</style>